// Core
import React, { useState } from 'react';
// Styles
import './styles.scss';
import * as L from 'leaflet';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import Button from 'components/commonComponents/Button';
import { useTranslation } from 'react-i18next';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


const MapPicker = (props) => {
  const { onClose, open, position } = props;
  const { t } = useTranslation();
  const [initialPosition, setInitialPosition] = useState([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  const Markers = () => {
    const map = useMapEvents({
      click(e) {
        setSelectedPosition([
          e.latlng.lat,
          e.latlng.lng
        ]);
      },
    })

    return (
      selectedPosition ?
        <Marker
          key={selectedPosition[0]}
          position={selectedPosition}
          interactive={false}
        />
        : null
    )
  }

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose(selectedPosition);
    }
  };


  return (
    <>



      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class_tabs'
        maxWidth={'md'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
        {t('map')}
        </DialogTitle>
        <DialogContent>

          <MapContainer
            center={selectedPosition || initialPosition}
            zoom={2}
            style={{ height: "450px", width: "100%" }}
          >
            <Markers />
            <TileLayer
              attribution='...'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('ok')}
            />
          </div>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default MapPicker;

MapPicker.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  position: PropTypes.any
};
