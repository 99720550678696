import { BASE_URL, USER_HOST } from 'config/constants';


export const restAPIs = {
  login: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_login.php`
  }),
  signUp: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_userRegistration.php`
  }),
  changePassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changePassword`
  }),
  changeProfPic: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeProfilePic`
  }),
  changeLanguage: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeLanguage`
  }),
  otpVerify: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_otpverify`
  }),
  forgotPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_forgotPassword`
  }),
  resetPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_resetPassword`
  }),
  changeName: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeUsername.php`
  }),
  getWallet: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/wallet.php`
  }),
  getCountryList: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php`
  }),
  contactUs: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_contactus.php`
  }),
  changeEmail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeEmail`
  }),
  changeEmailOTP: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_sendOtp`
  }),
  deleteAccount: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_deleteUser`
  }),
  etisalatEncryption: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/etisalat_encrypt.php`
  }),
  getMyTrips:()=> ({
    method : 'get',
    endpoint : `${BASE_URL}/json_my_trips.php`
  }),
  getFavoriteTrips:()=> ({
    method : 'get',
    endpoint : `${BASE_URL}/json_favourite_trips.php`
  }),
  getSharedWithMe:()=> ({
    method : 'get',
    endpoint : `${BASE_URL}/json_sharedWithMe_trips.php`
  }),
  getMyShares:()=> ({
    method : 'get',
    endpoint : `${BASE_URL}/json_sharedWithOthers_trips.php`
  }),
  getTripDetail:(id, language) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_trip_indetail.php?tripId=${id}&contentLanguage=${language}`
  }),
  getFeaturedTrips:(page, language) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_featured_trips.php?page=${page}&contentLanguage=${language}`
  }),
  getRecentPublicTrips:(page, language) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_recent_public_trips.php?page=${page}&contentLanguage=${language}`
  }),
  getArticles: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_articles.php`
  }),
  addTrip: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_trip_form.php`
  }),
  addArticle: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_article_form.php`
  }),
  getArticleDetail:(tripId, articleId, language) => ({
    method : 'get',
    endpoint: `${BASE_URL}/json_article_indetail.php?contentLanguage=${language}&articleId=${articleId} ${tripId ? `&tripId=${tripId}` : ''}`
  }),

  getTripComments: (tripId)=> ({
    method : 'get',
    endpoint: `${BASE_URL}/json_trip_comments.php?tripId=${tripId}`
  }),
  addCommentTrip: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_trip_comment_form.php`
  }),
  getArticleComments: (tripId, articleId)=> ({
    method : 'get',
    endpoint: `${BASE_URL}/json_article_comments.php?tripId=${tripId}&articleId=${articleId}`
  }),
  addCommentArticle: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_article_comment_form.php`
  }),
  addFavoriteTrip: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_fav_trip_form.php`
  }),
  createPDF: (tripId) =>({
    method : 'get',
    endpoint: `${BASE_URL}/pdf_generate.php?tripId=${tripId}`
  }),
  getAppInfo: (appId, language) =>({
    method : 'get',
    endpoint: `https://www.electronicvillage.org/json_appInfo.php?appId=${appId}&language=${language}`
  }),
  getFounderInfo: (language) =>({
    method : 'get',
    endpoint: `https://www.electronicvillage.org/json_founder.php?language=${language}`
  }),
  getEVApps: (language, appId=null) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_applications.php?language=${language}${appId? '&appId='+appId : ''}`
  }),
  getUsers:(body) =>({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_user_search.php`
  }),
  shareTripWithPublic:(body) =>({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_trip_public_form.php`
  }),
  shareTripWithUsers:(body) =>({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_trip_sharing_form.php`
  }),
  articlesByLoaction: (tripId) =>({
    method : 'get',
    endpoint: `${BASE_URL}/json_articlesByLocation.php?tripId=${tripId}`
  }),
  articlesByLocationName: (tripId, locationName) =>({
    method : 'get',
    endpoint: `${BASE_URL}/json_articlesByLocationName.php?tripId=${tripId}&locationName=${locationName}`
  }),
  getAstroInDetail:(body) =>({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_astro_indetail.php`
  }),
  translateData: (client, sl, tl, dt, q) =>({
    method : 'get',
    endpoint: `https://translate.googleapis.com/translate_a/single?client=${client}&sl=${sl}&tl=${tl}&dt=${dt}&q=${q}`
  }),
  getSubscriptions: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscriptions.php`
  }),
  subscriptionForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscription_form.php`
  }),
  encryption: (data) => ({
    method: 'post',
    endpoint: `${USER_HOST}/etisalat_encrypt.php?data=${data}`
  }),
  etisalatTerms:(pageId, language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?pageId=${pageId}&language=${language}`
  }),
  simBilling:(body) =>({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/etisalatsimbilling.php`
  }),
  tripLike:(body) =>({
    method:'post',
    body,
    endpoint:`${BASE_URL}/json_trip_like_form.php`
  }),
  getDashboard:(language) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_dashboard.php?language=${language}`
  }),
  awardsByType:(language, type) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_ibAwards_byType.php?language=${language}&groupType=${type}`
  }),
  awardsList:(language, categoryId, year, page) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_ibAwards.php?language=${language}${categoryId ? `&categoryId=${categoryId}` : ''}${year ? `&year=${year}` : ''}${page ? `&page=${page}` : ''}`
  }),
  awardsByLocation:(language) => ({
    method : 'get',
    endpoint : `${BASE_URL}/json_ibawards_locations.php?language=${language}`
  }),
  bookInfo:(bookId, language) =>({
    method : 'get',
    endpoint :`https://alwaraq.net/json_booklist.php?ibAwards=1&bookId=${bookId}&language=${language}`
  }),
  bookByLocation:(language, latitude, longitude) =>({
    method : 'get',
    endpoint :`https://alwaraq.net/json_booklist.php?ibAwards=1&latitude=${latitude}&language=${language}&longitude=${longitude}`
  }),
  getEVPages:(language, pageId) => ({
    method : 'get',
    endpoint : `https://www.electronicvillage.org/json_evPages.php?language=${language}&pageId=${pageId}`
  }),
  searchAll:(body) =>({
    method:'post',
    body,
    endpoint : `${BASE_URL}/json_searchAll.php`
  }),
  searchArticles:(body) =>({
    method:'post',
    body,
    endpoint : `${BASE_URL}/json_searchTrip.php`
  }), 
  helpAndSupport:(language) =>({
    method:'get',
    endpoint: `${USER_HOST}/json_appHelpSupport.php?appId=29&language=${language}`
  }),
  myWallet:(language) =>({
    method:'get',
    endpoint: `https://www.electronicvillage.org/json_page.php?pageId=39&language=${language}`
  }),
  nominationForm:(body) =>({
    method:'post',
    body,
    endpoint:`${USER_HOST}/json_ibawards_nominate.php`,
  }),
  countryInfos:(language) =>({
    method:'get',
    endpoint: `${USER_HOST}/countryinfos.php?language=${language}`
  }),

  unSubscribe:(body)=>({
    method:'post',
    body,
    endpoint:`${USER_HOST}/unsubscribe.php`,
  }),
  chatGPT :(body)=>({
    method:'post',
    body,
    endpoint:`https://evusers.electronicvillage.org/chatgpt`,
  }),

  getChatFAQ:(subjectId, language, subSubjectId) =>({
    method:'get',
    endpoint: `${USER_HOST}/json_chatGPT_list.php?appId=29&subjectId=${subjectId}&language=${language}&subSubjectId=${subSubjectId}`
  }),

  healthScanFedo:(body) =>({
    method:'post',
    body,
    endpoint:`${USER_HOST}/json_healthScanFedo.php`
  })
};
