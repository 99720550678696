// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import validator from 'validator';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';
import InputField from 'components/commonComponents/InputField';
import Select from 'components/commonComponents/Select';
import Button from 'components/commonComponents/Button';
import TextArea from 'components/commonComponents/TextArea';
import { Alert, Chip, Fab, Snackbar, TextField, Button as ButtonMUI } from '@mui/material';


// icons
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import EventIcon from '@mui/icons-material/Event';
// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment/moment';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import MapPicker from 'components/modals/MapPicker';


const AddArticle = () => {
    const { t } = useTranslation();
    const [articleDetail, setArticleData] = useState('')
    const [data, setData] = useState({});
    const hiddenCoverImg = useRef(null);
    const moreImages = useRef(null)
    const { tripId, articleId } = useParams();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [changeCoverImg, setCoverChangeImage] = useState({});
    const [links, setLinks] = useState([]);
    const [changeMoreImgs, setMoreImages] = useState([]);
    const [assets, setAssets] = useState([]);
    const navigate = useNavigate();
    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [mapOpen, setMapOpen] = useState(false)
    const {
        date = articleDetail ? moment(new Date()).format(articleDetail.date) : moment(new Date()).format('YYYY-MM-DD hh:mm A'),
        title = articleDetail ? articleDetail.articleName : '',
        location = articleDetail && articleDetail!=null ? [
            articleDetail.location?.latitude,
            articleDetail.location?.longitude
          ] : [0,0],
        about = articleDetail ? articleDetail.description : '',
        url = ''
    } = data;


    useEffect(() => {
        if (articleId)
            fetchArticle();
    }, []);

    const handleMapIconClick = () =>{
        setMapOpen(true)
    }
    const handleCloseMap= (value)=>{
        setMapOpen(false)
        setData({
            ...data,
            location: value
        });
    }

    const formatValue= (value) =>{
        return value[0] + ',  ' + value[1]
    }


    const handleOnChange = (key) => (value) => {
        const isDate = ['date'].includes(key);
        const __value = isDate ? moment(value).format('YYYY-MM-DD hh:mm A') : value
        setError({
            ...error,
            [key]: null
        });
        setData({
            ...data,
            [key]: __value
        });
    };


    const handleIconPlusClick = () => {
        const errors = {
            url: !validator.isURL(url) && 'Enter valid URL',
        };
        setError(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        if (!error.url) {
            let linkSet = links
            linkSet.push(url)
            setLinks(linkSet)
            data.url = '';
        }
    };

    const handleDeleteURL = (value) => () => {
        let index = links.indexOf(value);
        if (index > -1) {
            let result = links.filter(function (ele) {
                return ele != value;
            });
            setLinks(result)
        }
    }

    const handleCoverImgInput = (file) => {
        let files = file.target.files
        var reader = new FileReader();
        reader.readAsDataURL(files.item(0));
        reader.onload = (_event) => {
            setCoverChangeImage({ img: reader.result, file: files.item(0) })
        }
    }

    const handleCoverClick = event => {
        hiddenCoverImg.current.click();
    };

    const removeCoverImg = () => {
        setCoverChangeImage({})
    }

    const handleMoreImgInput = (file) => {
        let files = file.target.files
        var reader = new FileReader();
        reader.readAsDataURL(files.item(0));
        reader.onload = (_event) => {
            let imgs = changeMoreImgs
            setMoreImages(imgs.concat({ img: reader.result, file: files.item(0) }))
            let asts = assets
            asts.push(files.item(0))
            setAssets(asts)
        }
    }

    const handleMoreImgClick = event => {
        moreImages.current.click();
    };

    const handleRemoveImg = (value) => () => {
        let index = changeMoreImgs.indexOf(value);
        if (index > -1) {
            let result = changeMoreImgs.filter(function (ele) {
                return ele != value;
            });
            let resAss = assets.filter(function (ele) {
                return ele != value.file;
            });
            setMoreImages(result)
            setAssets(resAss)
        }
    }

    const handleSave = async () => {
        const errors = {
            date: !date,
            title: !title,
            location: !location,
            about: !about,
        };

        setError(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('action', articleId ? 'edit' : 'add');
        if (articleId)
            formData.append('articleId', articleId);
        formData.append('title', title);
        formData.append('tripId', tripId);
        formData.append('date', date);
        formData.append('body', about);
        formData.append('latitude', location[0]);
        formData.append('longitude', location[1]?location[1]:0);
        formData.append('coverImage', changeCoverImg.file);
        links.forEach((element, index) => {
            formData.append('links[' + index + ']', element);
        });
        assets.forEach((element, index) => {
            formData.append('assets[]', element);
        });
        const res = await fetchData(restAPIs.addArticle(formData));
        if (res.statusCode === 200) {
            setData({})
            setAssets([])
            setMoreImages([])
            setCoverChangeImage({})
            setLinks([])
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
            navigate('/article/' + tripId + '/' + res.article[0].articleId);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        setIsLoading(false);
    };


    const fetchArticle = async () => {
        const res = await fetchData(restAPIs.getArticleDetail(tripId, articleId));
        if (res?.statusCode === 200) {
            setArticleData(res?.article[0]);
            setLinks(res?.article[0].urls)
            let imageFile = await fetchArticleImg(res?.article[0].coverImage)
            setCoverChangeImage({ img: res?.article[0].coverImage, file: imageFile })
            let morImg = []
            let imgOmre = []
            res?.article[0].assets.forEach(async element => {
                let imageFiles = await fetchArticleImg(element)
                imgOmre.push({ img: element, file: imageFiles })
                morImg.push(imageFiles)
                setAssets(morImg)
                setMoreImages(imgOmre)
            });
        }
    };
    async function fetchArticleImg(url) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        try {
            const fileName = url && url.substring(url.lastIndexOf('/') + 1)
            let result = null
            const response = await fetch(url, requestOptions)
                .then(response => response.blob())
                .then(blob => new File([blob], `${fileName}`, {
                    type: blob.type
                }))
                .then(file => {
                    result = file
                })
            return result

        } catch (error) {
            return console.log('error', error);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleIconPlusClick();
        }
      };

    return (
        <>
            <Banner
                title={t('article')}
                decription={t('add_your_favourite_trip_and_articles')}
                placeholder={t('search_for_destination_places_or_trips')}
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5'>
                <h1 className='__common_page_head'>{articleId ? 'Edit Article' : t('add_article')}</h1>
                <div className='row mb-5 __addtrip'>
                    <div className='mb-4'>
                        <InputField
                            type="text"
                            placeholder={t('enter_your_article_title')}
                            label={t('article_title')}
                            value={title}
                            onChange={handleOnChange('title')}
                            error={error.title}
                        />
                    </div>
                    <div className='mb-4'>
                        <InputField
                            type="text"
                            placeholder={t('location')}
                            label={t('location')}
                            onChange={handleOnChange('location')}
                            icon={<MapIcon />}
                            error={error.location}
                            onIconClick={handleMapIconClick}
                            value={location}
                        />
                        <MapPicker onClose={handleCloseMap} open={mapOpen} />
                    </div>
                    <div className='mb-4'>
                        <InputField
                            type="text"
                            placeholder={t('date')}
                            label={t('date')}
                            value={date}
                            icon={
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDateTimePicker
                                        label="Date&Time picker"
                                        value={date}
                                        onChange={handleOnChange('date')}
                                        open={calendarOpen}
                                        onClose={() => setCalendarOpen(false)}
                                        renderInput={(params) => (
                                            <div>
                                                <TextField style={{ opacity: 0, width: 0, height: 0, visibility: 'hidden' }} {...params} />
                                                <ButtonMUI
                                                    className="__custom_caleder_btn"
                                                    variant="text"
                                                    onClick={() => setCalendarOpen((calendarOpen) => !calendarOpen)}
                                                >
                                                    <EventIcon />
                                                </ButtonMUI>
                                            </div>
                                        )}

                                    />
                                </LocalizationProvider>
                            }
                            error={error.date}
                        />


                    </div>
                    <div className='mb-4'>
                        <TextArea
                            type="text"
                            placeholder={t('write_about_your_article_article_description')}
                            label={t('about')}
                            value={about}
                            onChange={handleOnChange('about')}
                            height={200}
                            error={error.about}
                        />
                    </div>
                    <div className='mb-4'>
                        <InputField
                            type="text"
                            placeholder={t('add_url_and_media_links')}
                            label={t('url')}
                            value={url}
                            onChange={handleOnChange('url')}
                            onIconClick={handleIconPlusClick}
                            onKeyDown={handleKeyDown}
                            icon={<AddIcon />}
                            error={error.url}
                        />
                        <div className='mt-2'>

                            {links && links.map((item, idx) => {
                                return (
                                    <Chip key={idx} label={item} variant="outlined" onDelete={handleDeleteURL(item)} />
                                )
                            })}
                        </div>

                    </div>
                    <div className='__border_dashed'></div>
                    <h5>{t('images_and_media')}</h5>
                    <div className='mb-4'>
                        <div className='__cover_image' onClick={handleCoverClick}>
                            <div>
                                <AddAPhotoIcon />
                                <span>{t('add_cover_image')}</span>
                            </div>
                            <FileUploadOutlinedIcon />
                            <input type="file" accept=".png, .jpg, .jpeg" onChange={handleCoverImgInput} ref={hiddenCoverImg} style={{ display: 'none' }} />
                        </div>
                        {changeCoverImg?.img ?
                            <div className='__coverimg' >
                                <img src={changeCoverImg.img} />
                                <span onClick={removeCoverImg}>
                                    <Fab size="small" color="primary" aria-label="remove">
                                        <ClearIcon />
                                    </Fab></span>
                            </div> : ''
                        }
                    </div>
                    <h5>{t('more_images')}</h5>
                    <div className='mb-4'>
                        <div className='d-flex __more_img_arb'>
                            <div className='__more_img' onClick={handleMoreImgClick}>
                                <AddPhotoAlternateOutlinedIcon />
                                <input type="file" ref={moreImages} accept=".png, .jpg, .jpeg" style={{ display: 'none' }} onChange={handleMoreImgInput} />
                            </div>

                            {changeMoreImgs && changeMoreImgs.map((item, index) => {
                                return (
                                    <div className='__more_img_image' key={index}>
                                        <img src={item.img} />
                                        <span onClick={handleRemoveImg(item)}>
                                            <Fab size="small" color="primary" aria-label="remove">
                                                <ClearIcon />
                                            </Fab></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='mb-5 mt-4 text-center'>
                        <Button
                            label='Save Article'
                            icon={<EastOutlinedIcon />}
                            handleOnClick={handleSave}
                            withLoader
                            loading={isLoading}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
                    <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                        {errorAlert.msg}
                    </Alert>
                </Snackbar> : ''}
                <Download />
            </div>
        </>
    );
};

export default AddArticle;
