// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// components
import Article from 'components/commonComponents/Article';
import Button from 'components/commonComponents/Button';
import TextArea from 'components/commonComponents/TextArea';
import Comment from 'components/commonComponents/Comment';
import SliderBanner from 'components/commonComponents/SliderBanner';

import CommentIcon from '@mui/icons-material/Comment';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import MapIcon from '@mui/icons-material/Map';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PrintIcon from '@mui/icons-material/Print';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import SendIcon from '@mui/icons-material/Send';
// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Skelton from 'components/commonComponents/Skelton';
import { Button as ButtonMUI, Stack, Avatar as AvatarImg, Menu, IconButton, Alert, Snackbar, Fab, Tabs, Tab } from '@mui/material';
import ForumIcon from '@mui/icons-material/Forum';
import { useDispatch, useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditComment from 'components/modals/EditComment';

import { useSpeechSynthesis } from 'react-speech-kit';
import ReactPlayer from 'react-player'
import { BASE_URL } from 'config/constants';
import ShareTrip from 'components/modals/ShareTrip';
import SocialShare from 'components/modals/SocialShare';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Zodiac from 'components/modals/Zodiac';
import PremiumWidget from 'components/commonComponents/PremiumWidget';
import { SearchOutlined } from '@mui/icons-material';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import InputField from 'components/commonComponents/InputField';
import FAQ from 'components/modals/FAQ';

import QA from 'assets/images/question-and-answer.png'

const ViewTrip = () => {

    const { userDetails, currentLang, articlesList_Trip, currentTripId, searchArticle } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { tripId } = useParams();
    const [data, setData] = useState({});
    const [imageArray, setImageArray] = useState([]);
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [urls, setUrls] = useState([]);
    const [mapUrl, setMapUrl] = useState('')
    const [videos, setVideos] = useState([])
    const [assets, setAssets] = useState([])
    const [errorComment, setErrorComment] = useState('')
    const [commentData, setCommentData] = useState({})
    const [isLoadingComment, setIsLoadingComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const [anchorElMenu, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [openDeleteComment, setOpenDeleteComment] = useState(false);
    const { comment = '' } = commentData;
    const [openEditComment, setOpenEditComment] = useState('');
    const [isFavourite, setIsFavourite] = useState(false)
    const [valueToSpeak, setValueToSpeak] = useState('');
    const { speak, cancel, speaking } = useSpeechSynthesis();
    const commentsRef = useRef(null);
    const [anchorElMenuArticle, setAnchorElArticle] = useState(null);
    const [openMenuArticle, setOpenMenuArticle] = useState(null);
    const [openDeleteArticle, setOpenDeleteArticle] = useState(false);
    const [openShareTrip, setOpenShareTrip] = useState(false);
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [summary, setSummary] = useState('');
    const [openZodiac, setOpenZodiac] = useState(false);
    const [totalLikes, setTotalLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [relatedTrips, setRelatedTrips] = useState([])
    const [totalArticles, setTotalArticles] = useState(0)
    const [page, setPage] = useState(1);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [contentLanguage, setContentLanguage] = useState(searchArticle?.contentLanguage ? searchArticle?.contentLanguage : '')

    const [isLoadingCht, setIsLoadingCht] = useState(false)
    const [chatgptResponse, setChatgptResponse] = useState('')

    const [datacht, setDataCht] = useState({});
    const [error, setError] = useState('');

    const { searchPhrase } = datacht
    const GPTref = useRef(null)

    const [showFAQ, setShowFAQ] = useState(false)



    const handleClickMenuArticle = (event, value) => {
        setAnchorElArticle(event.currentTarget);
        setOpenMenuArticle(value)
    };
    const handleCloseMenuArticle = () => {
        setAnchorElArticle(null);
        setOpenMenuArticle(null)
    };

    const handleClickMenu = (event, value) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(value)
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpenMenu(null)
    };

    const fetchTrip = async () => {
        let lang = currentLang?.id;
        if (getParameterByName('contentLanguage', window.location.href)) {
            lang = (getParameterByName('contentLanguage', window.location.href)) == 'ar' ? 1 : 2;
        }
        const res = await fetchData(restAPIs.getTripDetail(tripId, lang));
        if (res?.statusCode === 200) {
            setImageArray([res.trip[0].coverImage, ...res.trip[0].assets])
            setData(res.trip[0]);
            setValueToSpeak(res.trip[0].tripName.concat(res.trip[0].description))
            setIsFavourite(res.trip[0].isFavourite)
            setUrls(res.trip[0].urls)
            let url = "https://maps.google.com/maps?q=" + res.trip[0]?.location?.latitude + "," + res.trip[0]?.location?.latitude + "&output=embed&zoom=1"
            setMapUrl(url)
            setVideos(res.trip[0].media)
            setAssets(res.trip[0].assets)
            setIsLiked(res.trip[0].isLiked)
            let sum = res.trip[0].tripName + '\n\n' + res.trip[0].description + '\n\n'
            res.trip[0].urls.forEach(element => {
                sum += element + '\n'
            });
            sum += 'From Ibn Battuta Project.\nBy Electronic Village \n\nAppstore : http://ibios.electronicvillage.org/ \nPlaystore : http://ibandroid.electronicvillage.org/\nWeb:'
            setSummary(sum)
        }
    };

    const fetchArticles = async () => {
        let lang = currentLang?.id;
        if (getParameterByName('contentLanguage', window.location.href)) {
            lang = getParameterByName('contentLanguage', window.location.href) == 'ar' ? 1 : 2
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.append('tripId', tripId);
        formData.append('contentLanguage', lang);
        formData.append('page', page);
        const res = await fetchData(restAPIs.getArticles(formData));
        setIsLoading(false)
        if (res?.statusCode === 200) {
            dispatch({
                type: 'articlesList_Trip',
                value: res.articles
            });
            setIsLastPage(res.isLastPage);
            setTotalArticles(res.total)
        }
    };

    const fetchComments = async () => {
        const res = await fetchData(restAPIs.getTripComments(tripId));
        if (res?.statusCode === 200) {
            setComments(res.comments);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setContentLanguage(searchArticle?.contentLanguage)
        fetchTrip();
        fetchComments();
        isLikedTrip();
        fetchRelatedTrips();
    }, [tripId, currentLang]);

    useEffect(() => {
        if (currentTripId != tripId)
            fetchArticles();
    }, [currentLang]);


    const handleOnClickArticle = (id) => {
        navigate('/article/' + tripId + '/' + id);
    }

    const handleClickAddArticle = () => {
        navigate('/add-article/' + tripId);
    }

    const handleEditTrip = () => {
        navigate('/edit-trip/' + tripId);
    }

    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };
    const handleOpenLoginModel = () => {
        setShowLogin(true);
    }

    const handleDeleteCommentClick = () => {
        setOpenDeleteComment(true);
    };

    const handleEditCommentClick = (value) => {
        setOpenEditComment(value);
    };

    const handleCloseEditComment = (value) => {
        setOpenEditComment('');
        handleCloseMenu();
        if (value)
            fetchComments();
    }

    const handleCloseDeleteComment = async (action, commentId) => {
        setOpenDeleteComment(false);
        if (action) {
            setAnchorEl(null);
            setOpenMenu(null)
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('tripId', tripId);
            formData.append('commentId', commentId);
            const res = await fetchData(restAPIs.addCommentTrip(formData));
            if (res.statusCode === 200) {
                fetchComments();
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            else {
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
        }
    };

    const handleOnChangeComment = (key) => (value) => {
        setErrorComment({
            ...errorComment,
            [key]: null
        });
        setCommentData({
            ...commentData,
            [key]: value
        });
    };

    const handleSubmitComment = async () => {
        const errors = {
            comment: !comment,
        };
        setErrorComment(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoadingComments(true);
        const formData = new FormData();
        formData.append('action', 'add');
        formData.append('tripId', tripId);
        formData.append('comment', comment);
        const res = await fetchData(restAPIs.addCommentTrip(formData));
        if (res.statusCode === 200) {
            fetchComments();
            setCommentData({})
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        setIsLoadingComments(false);
    };

    const handleSpeak = () => {
        speak({ text: valueToSpeak && valueToSpeak, rate: 1.2 })
    }

    const handleFavorite = async () => {
        if (userDetails) {
            let isFav = !isFavourite
            const formData = new FormData();
            formData.append('action', 'add');
            formData.append('tripId', tripId);
            const res = await fetchData(restAPIs.addFavoriteTrip(formData));
            if (res.statusCode === 200) {
                setIsFavourite(isFav)
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            else {
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }

        }
        else { setShowLogin(true) }
    }

    const handlePrint = async () => {
        window.open(`${BASE_URL}/pdf_generate.php?tripId=${tripId}`, '_blank');
    }

    const scrollToBottom = (checkRef) => {
        if (checkRef === 'commentsRef')
            commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
        if(checkRef === 'GPTref')
        GPTref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleEditClickArticle = (articleId) => {
        navigate('/edit-article/' + tripId + '/' + articleId);
    }

    const handleDeleteClickArticle = () => {
        setOpenDeleteArticle(true);
    };

    const handleCloseDeleteArticle = async (action, articleId, trip) => {
        setOpenDeleteArticle(false);
        if (action) {
            setAnchorEl(null);
            setOpenMenu(null)
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('tripId', tripId);
            formData.append('articleId', articleId);
            const res = await fetchData(restAPIs.addArticle(formData));
            if (res.statusCode === 200) {
                fetchArticles();
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            else {
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
        }
    };

    const handleOpenShareTrip = () => {
        setOpenShareTrip(true);
    };
    const handleCloseShareTrip = (event, errormessage) => {
        setOpenShareTrip(false);
        if (event) {
            fetchTrip();
            setErrorAlert({ msg: errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
    };

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };


    const handleOpenZodiac = () => {
        setOpenZodiac(true);
    };
    const handleCloseZodiac = () => {
        setOpenZodiac(false);
    };

    const handleViewOnMap = () => {
        navigate('/map/' + tripId);
    }

    const handleSearch = () => {
        dispatch({
            type: 'searchArticle',
            value: {
                searchPhrase: '',
                articles: []
            }
        });
        navigate('/search/articles/' + tripId)
    }


    const [valueTab, setValueTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const isLikedTrip = async () => {
        const formData = new FormData();
        formData.append('tripId', tripId);
        const res = await fetchData(restAPIs.tripLike(formData));
        if (res.statusCode === 200) {
            setTotalLikes(+res.totalLikes)
        }
    }
    const likeTrip = async () => {
        const formData = new FormData();
        formData.append('action', isLiked ? 'delete' : 'add');
        formData.append('tripId', tripId);
        const res = await fetchData(restAPIs.tripLike(formData));
        if (res.statusCode === 200) {
            setIsLiked(!isLiked)
            setTotalLikes(+res.totalLikes)
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
    }

    const fetchRelatedTrips = async () => {
        const res = await fetchData(restAPIs.getFeaturedTrips(1, currentLang?.id));
        if (res?.statusCode === 200) {
            setRelatedTrips(res.featured)
        }
    }

    const handleOnClickTrip = (item) => {
        dispatch({
            type: 'currentTripId',
            value: item.tripId
        });
        item.isPremium ? userDetails ? userDetails.subscription !== 0 ? navigate('/trip/' + item.tripId) : navigate('/subscribe') : setShowLogin(true) : navigate('/trip/' + item.tripId)
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginateArticles(curren + 1)
    }

    const fetchPaginateArticles = async (page = 1) => {
        let lang = currentLang?.id;
        if (getParameterByName('contentLanguage', window.location.href)) {
            lang = getParameterByName('contentLanguage', window.location.href) == 'ar' ? 1 : 2
        }
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('tripId', tripId);
        formData.append('page', page);
        formData.append('contentLanguage', lang)
        const res = await fetchData(restAPIs.getArticles(formData));
        setLoadingPage(false)
        if (res?.statusCode === 200) {
            dispatch({
                type: 'currentTripId',
                value: tripId
            });
            dispatch({
                type: 'articlesList_Trip',
                value: articlesList_Trip.concat(res.articles)
            });
            setIsLastPage(res.isLastPage)
        }
    }

    const getParameterByName = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setDataCht({
            ...datacht,
            [key]: value
        });
    };

    const handleSaveChtGpt = async () => {

        if (userDetails) {
            if (userDetails.subscription) {
                const errors = {
                    searchPhrase: !searchPhrase,
                };
                setError(errors);
                let isFormValid = Object.values(errors).every((item) => !item);
                if (!isFormValid) return;
                setIsLoadingCht(true)
                const formData = new FormData();
                formData.append('appId', '29');
                formData.append('language', currentLang?.id);
                formData.append('searchphrase', btoa(unescape(encodeURIComponent(searchPhrase))))
                formData.append('subject', btoa(unescape(encodeURIComponent(`Place name : ${data?.tripName}.`))))
                formData.append('subjectId', tripId);
                const res = await fetchData(restAPIs.chatGPT(formData));
                if (res.statusCode === 200) {
                    setChatgptResponse({ answer: res?.answer, question: res?.question })
                    setIsLoadingCht(false)
                }
            }
            else {
                navigate('/subscribe')
            }
        }
        else {
            setShowLogin(true)
        }
    }

    // const handleNavigateFAQ = () =>{
    //     userDetails? userDetails?.subscription ? navigate(`/chatFAQ?subjectId=${tripId}`) : navigate('/subscribe') : setShowLogin(true)
    // }

    const handleNavigateFAQ = () => {
       userDetails ? userDetails?.subscription ? setShowFAQ(true) : navigate('/subscribe') : setShowLogin(true)
    }

    const handleCloseModelFAQ = () => {
        setShowFAQ(false)
    }

    return (
        <>
            <div className='container'>
                <div className='__banner_set pb-3'>
                    <SliderBanner
                        imageArray={imageArray}
                    />
                </div>
                <div className='__detail_content_wrapper mt-3'>
                    <div className='__main_content'>
                        <div className='__fab_set'>

                            {
                                data?.isOwner ? <Fab style={{ backgroundColor: '#0284c5', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Share Trip" onClick={handleOpenShareTrip}>
                                    <ReplyIcon />
                                </Fab> : ''}

                            {
                                data?.isOwner ? <Fab style={{ backgroundColor: '#8e8c8c', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Edit Trip" onClick={handleEditTrip}>
                                    <EditIcon />
                                </Fab> : ''}

                            {
                                isFavourite ? <Fab style={{ backgroundColor: '#e91e63', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Favorite" onClick={handleFavorite} >
                                    <FavoriteIcon />
                                </Fab> : <Fab style={{ backgroundColor: '#e91e63', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Favorite" onClick={handleFavorite}>
                                    <FavoriteBorderIcon />
                                </Fab>
                            }

                            {
                                data?.isOwner ? <Fab style={{ backgroundColor: '#607d8b', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Print" onClick={handlePrint}>
                                    <PrintIcon />
                                </Fab> : ''}

                            { data?.contentLanguage === 'en' ? speaking ?
                                <Fab style={{ backgroundColor: '#ff9800', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' title='Stop' aria-label="Speech" onClick={cancel}>
                                    <VolumeMuteIcon />
                                </Fab> :
                                <Fab style={{ backgroundColor: '#ff9800', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' title='Start' aria-label="Speech" onClick={() => handleSpeak()}>
                                    <VolumeUpIcon />
                                </Fab> : ''
                            }
                            <Fab style={{ backgroundColor: '#fff', marginLeft: '10px', color: '#000', zIndex: 0 }} size='small' aria-label="Zodiac" onClick={() => handleOpenZodiac()}>
                                <StarsOutlinedIcon />
                            </Fab>

                            <Zodiac open={openZodiac} onClose={handleCloseZodiac} date={data && data.date} />

                            <Fab style={{ marginLeft: '10px' }} size='small' aria-label="Search" onClick={() => handleSearch()}>
                                <SearchOutlined />
                            </Fab>


                        </div>
                        <h1>{data?.tripName}</h1>
                        <p style={{ fontSize: '12px' }}>{data?.displayDate}</p>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <AvatarImg alt={data?.author?.userName} src={data?.author?.profilePic} sx={{ width: 25, height: 25 }} />
                            <h6 style={{ margin: '0 16px', fontSize: '14px' }}>{data?.author?.userName}</h6>
                        </Stack>
                        <div className='__icon_set'>
                            <div>
                                <a onClick={() => scrollToBottom('commentsRef')}>
                                    <CommentIcon />
                                    <span>{t('comments')} ({comments && comments.length})</span>
                                </a>

                                <a onClick={handleViewOnMap} >
                                    <MapIcon />
                                    <span>{t('view_on_map')}</span>
                                </a>
                                <a onClick={() => likeTrip()}>
                                    {isLiked ? <ThumbUpAltIcon sx={{ color: '#63bdf1' }} /> : <ThumbUpOffAltIcon />}
                                    <span>{t('likes')} ({totalLikes})</span>
                                </a>
                                <a onClick={() => scrollToBottom('GPTref')}>
                                    <QuestionMarkIcon />
                                    <span>{t('Ask Anything')}</span>
                                </a>
                            </div>

                            <a style={{ margin: 0 }} onClick={handleOpenShareSocial}>
                                <ShareIcon />
                                <span>{t('share')}</span>
                            </a>
                            <SocialShare
                                open={openShareSocial}
                                onClose={handleCloseShareSocial}
                                title={data?.tripName}
                                description={data?.description}
                                image={data?.coverImage}
                                links={urls}
                                url={window.location.href}
                                summary={summary}
                            />
                            <ShareTrip open={openShareTrip} onClose={handleCloseShareTrip} tripId={tripId} isPublic={data?.isPublic} />

                        </div>

                        <div >
                            <Tabs value={valueTab} onChange={handleChangeTab} variant="fullWidth" >
                                <Tab label={t('articles')} {...a11yProps(0)} />
                                <Tab label={t('media')} {...a11yProps(1)} />
                                <Tab label={t('about_trip')} {...a11yProps(2)} />
                            </Tabs>

                            <div className=''>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 2}
                                    id={`simple-tabpanel-2`}
                                    aria-labelledby={`simple-tab-2`}
                                >
                                    <div className='mt-3'>
                                        {/* <h2>{t('about_trip')}</h2> */}
                                        <p className='__para' dangerouslySetInnerHTML={{ __html: data?.description }}></p>

                                        {imageArray && imageArray.length > 0 ? <h2>Photos</h2> : ''}
                                        <PhotoProvider>
                                            <div className='__photos-list mt-3'>

                                                {imageArray && imageArray.map((item, idx) => {
                                                    return (
                                                        <PhotoView src={item} >
                                                            <img src={item} alt="" key={idx} loading="lazy" />
                                                        </PhotoView>
                                                    )
                                                })}
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </PhotoProvider>

                                        {urls.length > 0 ? <h2>{t('links')}</h2> : ''}
                                        {
                                            urls && urls.map((item, idx) => {
                                                return (
                                                    <p className='d-flex align-items-center'> <a href={item} target="_blank" rel="noreferrer" style={{ fontSize: '18px' }} ><ArrowRightIcon style={{ margin: '0 10px' }} />{item}</a></p>
                                                )
                                            })}

                                        <h2 className='mt-3'>{t('map')}</h2>
                                        <iframe title='map' src={mapUrl} width="100%" height="270" frameborder="0"
                                            style={{
                                                border: 0,
                                                borderRadius: '20px',
                                                boxShadow: '1px 1px 10px #0003',
                                                margin: '15px 0',
                                                padding: '5px'
                                            }}
                                        ></iframe>

                                    </div>
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 0}
                                    id={`simple-tabpanel-0`}
                                    aria-labelledby={`simple-tab-0`}
                                >
                                    <div>
                                        <div className='d-flex justify-content-between align-items-center mt-3'>
                                            <h2>{t('articles')} ({totalArticles && totalArticles})</h2>
                                            {
                                                data?.isOwner ?
                                                    <Button
                                                        label='Add Article'
                                                        style={{ margin: '0' }}
                                                        handleOnClick={handleClickAddArticle}
                                                    />
                                                    : ''
                                            }
                                        </div>
                                        {articlesList_Trip && articlesList_Trip.length > 0 ?
                                            <div className='__article-list'>
                                                {isLoading ?
                                                    [...Array(3)].map((item, idx) => {
                                                        return (
                                                            <Skelton />
                                                        )
                                                    })
                                                    :
                                                    articlesList_Trip && articlesList_Trip?.map((item, idx) => {
                                                        return (
                                                            <div style={{ position: 'relative' }} key={idx}>
                                                                {data?.isOwner ? <div key={idx + item.articleId} className='__trip_dot_icon'>
                                                                    <IconButton
                                                                        aria-controls={openMenuArticle ? `basic-menu${item.articleId}` : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={openMenuArticle ? 'true' : undefined}
                                                                        onClick={(e) => handleClickMenuArticle(e, idx)}>
                                                                        <MoreVertIcon />
                                                                    </IconButton>

                                                                    <Menu
                                                                        id={`basic-menu${item.articleId}`}
                                                                        anchorEl={anchorElMenuArticle}
                                                                        open={openMenuArticle === idx}
                                                                        onClose={handleCloseMenuArticle}
                                                                        className="__trip_edit_menu"
                                                                    >
                                                                        <MenuItem key={idx + item.articleId + 5} onClick={() => handleEditClickArticle(item.articleId)}>{t('edit')}</MenuItem>
                                                                        <MenuItem key={idx + item.articleId + 10} onClick={() => handleDeleteClickArticle()}>{t('delete')}</MenuItem>
                                                                        <Dialog
                                                                            open={openDeleteArticle}
                                                                            onClose={handleCloseDeleteArticle}
                                                                            aria-labelledby="alert-dialog-title"
                                                                            aria-describedby="alert-dialog-description"
                                                                        >
                                                                            <DialogTitle id="alert-dialog-title">
                                                                                {t('delete')}
                                                                            </DialogTitle>
                                                                            <DialogContent>
                                                                                <DialogContentText id="alert-dialog-description">
                                                                                    Are you sure you want to Remove this Article.?
                                                                                </DialogContentText>
                                                                            </DialogContent>
                                                                            <DialogActions>
                                                                                <a onClick={() => handleCloseDeleteArticle(false)} style={{ cursor: 'pointer' }}> {t('cancel')}</a>
                                                                                <a onClick={() => handleCloseDeleteArticle(true, item.articleId, item)} style={{ margin: '0 15px', fontWeight: 'bold', marginLeft: '30px', cursor: 'pointer' }}>{t('ok')}</a>
                                                                            </DialogActions>
                                                                        </Dialog>
                                                                    </Menu>
                                                                </div> : ''}
                                                                <Article
                                                                    title={item.articleName}
                                                                    para={item.displayDate}
                                                                    image={item.coverImage}
                                                                    handleOnClick={() => handleOnClickArticle(item.articleId)}
                                                                />
                                                            </div>

                                                        )
                                                    })
                                                }
                                                {isLoadingPage ?
                                                    [...Array(2)].map((item, idx) => {
                                                        return (
                                                            <Skelton />
                                                        )
                                                    })
                                                    : ''}
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            : ''}
                                        {articlesList_Trip && articlesList_Trip.length == 0 ? t('no_articles') : ''}
                                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                                            {isLastPage ? '' : <ButtonMUI onClick={handlePageClick} className='_hand_btn'>{t('more')} </ButtonMUI>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 1}
                                    id={`simple-tabpanel-1`}
                                    aria-labelledby={`simple-tab-1`}
                                >
                                    <div className='mt-3'>
                                        {videos.length > 0 ? <h2>Videos</h2> : ''}
                                        <div className='__videos-list mt-3'>
                                            {videos && videos.map((item, idx) => {
                                                return (
                                                    <div>
                                                        <div className='player-wrapper'>
                                                            <ReactPlayer url={item.video} controls={true}
                                                                className='react-player'
                                                                width='100%'
                                                                height='100%'
                                                            />

                                                        </div>
                                                        <p className='text-center' style={{ fontSize: '15px', color: '#000', marginTop: '10px' }}>{item?.name}</p>
                                                    </div>
                                                )
                                            })}
                                            <div></div>
                                            <div></div>
                                        </div>
                                        {videos.length == 0 ? "No Videos" : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=' pt-5 ' ref={GPTref}></div>
                        <div className="_cht_full mt-5"  >
                            <h4 className="__chat_heading">{t('Ask_about_this_book_and_get_your_answers_instantly')}</h4>
                            <div className="__input_gpt">
                                <InputField
                                    type="text"
                                    placeholder={t('ask_anything')}
                                    onChange={handleOnChange('searchPhrase')}
                                    error={error.searchPhrase}
                                    value={searchPhrase}
                                    name='searchPhrase'
                                />

                                <Button
                                    label={t('send')}
                                    icon={<EastOutlinedIcon />}
                                    handleOnClick={handleSaveChtGpt}
                                    withLoader
                                    loading={isLoadingCht}
                                    disabled={isLoadingCht}
                                />

                            </div>
                            <p  dir={currentLang?.id!=1?'rtl':'ltr'}> <a className='__faq' onClick={()=>handleNavigateFAQ()}><img src={QA} style={{width:'22px'}}/> {t('frequently_asked_questions')}</a></p>
                            <div className="__resp_gpt" >
                                <h3>{chatgptResponse?.question}</h3>
                                <p dangerouslySetInnerHTML={{ __html: chatgptResponse?.answer }}></p>
                            </div>
                        </div>

                        <h2 className='mb-3 mt-3' ref={commentsRef}>{t('comments')} ({comments && comments.length})</h2>
                        {comments && comments.length === 0 ? 'No Comments' : ''}
                        {
                            comments && comments?.map((item, idx) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Comment
                                            key={idx}
                                            image={item.profilePic}
                                            title={item.fullName}
                                            decription={item.comment}
                                            time={item.commentDate}
                                        />
                                        {item.isOwner ?
                                            <div key={idx + item.commentId} className='__comment_dot_icon'>
                                                <IconButton
                                                    aria-controls={openMenu ? `basic-menu${item.commentId}` : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                    onClick={(e) => handleClickMenu(e, idx)}>
                                                    <MoreVertIcon />
                                                </IconButton>

                                                <Menu
                                                    id={`basic-menu${item.commentId}`}
                                                    anchorEl={anchorElMenu}
                                                    open={openMenu === idx}
                                                    onClose={handleCloseMenu}
                                                    className="__comment_edit_menu"
                                                >
                                                    <MenuItem key={idx + 5} onClick={() => handleEditCommentClick(idx)}>{t('edit')}</MenuItem>
                                                    <EditComment
                                                        key={idx}
                                                        open={openEditComment === idx}
                                                        onClose={handleCloseEditComment}
                                                        commentData={item.comment}
                                                        tripId={tripId}
                                                        isArticle={false}
                                                        commentId={item.commentId}
                                                    />

                                                    <MenuItem key={idx + 10} onClick={() => handleDeleteCommentClick()}>{t('delete')}</MenuItem>
                                                    <Dialog
                                                        open={openDeleteComment}
                                                        onClose={() => handleCloseDeleteComment(false)}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                        disableEscapeKeyDown
                                                    >
                                                        <DialogTitle id="alert-dialog-title">
                                                            {t('delete')}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                Are you sure you want to remove this comment !
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <a onClick={() => handleCloseDeleteComment(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                            <a onClick={() => handleCloseDeleteComment(true, item.commentId)} style={{ margin: '0 15px', fontWeight: 'bold', marginLeft: '30px', cursor: 'pointer' }}>{t('ok')}</a>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Menu>
                                            </div> : ''
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className='__add_comment_wrap'>
                            <span>{t('read_smtg_u_like')}</span>
                            {
                                userDetails ?

                                    <div className='__comment_adding'>
                                        <img src={userDetails?.profile_pic} />
                                        <div>
                                            <TextArea
                                                type="text"
                                                placeholder={t('your_comment')}
                                                height={110}
                                                value={comment}
                                                onChange={handleOnChangeComment('comment')}
                                                error={errorComment.comment}
                                            />
                                            <Button
                                                // label={t('submit')}
                                                icon={<SendIcon />}
                                                className='__new_submit_btn'
                                                handleOnClick={handleSubmitComment}
                                                withLoader
                                                loading={isLoadingComment}
                                                disabled={isLoadingComment}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <Button
                                            label={t('write_a_comment')}
                                            className='__marg_'
                                            handleOnClick={handleOpenLoginModel}
                                        />
                                    </>
                            }
                        </div>
                    </div>
                    <div className='__related_content'>
                        <div className='mb-3'>
                            <PremiumWidget />
                        </div>
                        <h2>{t('related_trips')}</h2>
                        {relatedTrips &&
                            relatedTrips.map((item, idx) => {
                                return (
                                    idx % 3 ?
                                        <div className='__related_item' onClick={() => handleOnClickTrip(item)}>
                                            <img src={item?.coverImage} />
                                            <div>
                                                <h1>{item?.tripName}</h1>
                                                <p>{item?.description}</p>
                                            </div>
                                        </div> : ''
                                )
                            })
                        }

                    </div>
                </div>

            </div>
            {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
                <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                    {errorAlert.msg}
                </Alert>
            </Snackbar> : ''}

            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />

            <FAQ
                open={showFAQ}
                onClose={handleCloseModelFAQ}
                subjectId={tripId}
            />
        </>
    );
};

export default ViewTrip;
